// import Vue from 'vue'
import translate from 'i18n-jsautotranslate'
translate.setUseVersion2() //设置使用v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
// Vue.prototype.$translate = translate
export const langMixins = {
    components:{},
    data(){
        return{
            language:'en'
        }
    },
    created(){
        // this.languageAuto()
    },
    mounted(){},
    computed:{},
    methods:{
        // 翻译
        languageAuto() {
            
       
            // this.language == 'zh' ? (this.language = 'en') : (this.language = 'zh')
            localStorage.setItem('language', `{"language":"${this.language}"}`)
            if (this.language == 'zh') {
                console.log('转为中文')
                translate.changeLanguage('chinese_simplified')
            }
            if (this.language == 'en') {
                console.log('转为英文')
                translate.changeLanguage('english')
            }
            translate.execute() //进行翻译
        },
    }
}
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index'
Vue.prototype.$axios = axios

import store from './store/store'
import './style/global.scss'
import './style/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
Vue.use(ElementUI)
// import 'vue-pickers/example/css/app.279fbbfe.css';

import 'animate.css';
import axiosApi from './utils/api'
Vue.prototype.$axiosApi = axiosApi
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import cookies from './utils/cookie'


router.beforeEach((to,form,next)=>{
  const token = cookies.getToken()

  NProgress.start()
  // console.log('token',token,form,to )
  if(token){
    next()
  }else if(!token && to.meta.rule ){ 
    if( form.name =='login'){ 
      // console.log('return')
      NProgress.done()
      return
      // next({ name:'login' })
    }else{
      // console.log('login')
       next({ name:'login' })
    }
   
  }
  else{
    next()
  }
  
 
})

router.afterEach((to,form,next)=>{

  NProgress.done()
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')